/* Vertically aling on ion-content */
.ion-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

:root {
  --ion-color-primary: #8b7b71;
  --ion-color-primary-rgb: 217,0,18;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #534a44;
  --ion-color-primary-tint: #54595b;
}



.fullheight {
  height: 100%;
}

.xc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signatureborder {
  width: 100%;
  border: 1px dashed black;
}

.farabackground {
  background-color: var(--ion-color-favorite)
}

.mainmenubtn {
  height: 4em;
}

hr {
  background-color: black;
}

li::marker {
  color: var(--ion-color-highlight);
  margin-left: 0;
}

.bewerberstatus {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--ion-color-light)
}

.error-small {
  font-size: 0.8em;
}

.applicationPhoto {
  display: block;
  margin: 0 auto;
  max-height: 50vh;
  object-fit: contain;
}

.signature-border-bottom {
  border-bottom: 1px solid #ccc;
}

.ion-fab-button-center {
  display: inline-block;
}

.weeklyselector > .ios {
  --color-checked: white;
  --indicator-color: var(--ion-color-primary);
}

.elevation {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin: 16px;
}

.newButtonglow {
  animation: pulseLittle 1s infinite;
}

.signButtonPulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  50% {
    transform: scale(1.15);
  }
}

@keyframes pulseLittle {
  50% {
    opacity: 0.8;
    transform: scale(1.015);
    box-shadow: 0 0 10px 8px transparent;
  }
}

.fabBottom {
  margin-bottom: 3.5em;
}

.weekdaydivider {
  border-top: 1px solid var(--ion-color-primary);
}

:root {
  --ion-background-color: #ffffff
;
  --ion-background-color-rgb: 255,255,255
;

  --ion-text-color: #000000
;
  --ion-text-color-rgb: 0,0,0
;

  --ion-color-step-50: #f2f2f2
;
  --ion-color-step-100: #e6e6e6
;
  --ion-color-step-150: #d9d9d9
;
  --ion-color-step-200: #cccccc
;
  --ion-color-step-250: #bfbfbf
;
  --ion-color-step-300: #b3b3b3
;
  --ion-color-step-350: #a6a6a6
;
  --ion-color-step-400: #999999
;
  --ion-color-step-450: #8c8c8c
;
  --ion-color-step-500: #808080
;
  --ion-color-step-550: #737373
;
  --ion-color-step-600: #666666
;
  --ion-color-step-650: #595959
;
  --ion-color-step-700: #4d4d4d
;
  --ion-color-step-750: #404040
;
  --ion-color-step-800: #333333
;
  --ion-color-step-850: #262626
;
  --ion-color-step-900: #191919
;
  --ion-color-step-950: #0d0d0d
;
}