.menubutton {
    width: 150px;
    height: 100px;
}

:root {
    --ion-color-favorite: #54595B;
    --ion-color-favorite-rgb: 217,0,18;
    --ion-color-favorite-contrast: #ffffff;
    --ion-color-favorite-contrast-rgb: 255,255,255;
    --ion-color-favorite-shade: #2b2d2f;
    --ion-color-favorite-tint: #54595b;

    --ion-color-highlight: #8b7b71;
    --ion-color-highlight-rgb: 217,0,18;
    --ion-color-highlight-contrast: #ffffff;
    --ion-color-highlight-contrast-rgb: 255,255,255;
    --ion-color-highlight-shade: #534a44;
    --ion-color-highlight-tint: #54595b;
}

.ion-color-favorite {
    --ion-color-base: var(--ion-color-favorite);
    --ion-color-base-rgb: var(--ion-color-favorite-rgb);
    --ion-color-contrast: var(--ion-color-favorite-contrast);
    --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
    --ion-color-shade: var(--ion-color-favorite-shade);
    --ion-color-tint: var(--ion-color-favorite-tint);
}

.ion-color-farared {
    --ion-color-base: var(--ion-color-highlight);
    --ion-color-base-rgb: var(--ion-color-highlight-rgb);
    --ion-color-contrast: var(--ion-color-highlight-contrast);
    --ion-color-contrast-rgb: var(--ion-color-highlight-contrast-rgb);
    --ion-color-shade: var(--ion-color-highlight-shade);
    --ion-color-tint: var(--ion-color-highlight-tint);
}

.homescreenbackground {
    --background: none;
    background-image: url('../assets/background_home.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10px;
    padding-bottom: 10px;
}

.equal-height {
    height: 100%;
    object-fit: contain;
    display: block;
    margin: 0;
    padding: 0;
}